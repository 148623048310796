import "vuetify/styles";
import { createVuetify } from "vuetify";
import { fi, en } from "vuetify/locale";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { createI18n, useI18n } from "vue-i18n";
import { VTreeview } from "vuetify/labs/VTreeview";

const smpsThemeLight = {
  dark: false,
  colors: {
    primary: "#122d43",
    secondary: "#ecf4f7",
    accent: "#c3343e",
    error: "#c3343e",
  },
  variables: {},
};

const messages = {
  fi: {
    $vuetify: {
      ...fi,
      dataFooter: {
        itemsPerPageText: "Riviä per sivu:",
        pageText: "{0}-{1} / {2}",
        itemsPerPageAll: "Kaikki",
        firstPage: "Ensimmäinen sivu",
        prevPage: "Edellinen sivu",
        nextPage: "Seuraava sivu",
        lastPage: "Viimeinen sivu",
      },
    },
  },
  en: {
    $vuetify: {
      ...en,
    },
  },
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "fi",
  fallbackLocale: "en",
  messages,
});

const vuetify = createVuetify({
  components: {
    VTreeview,
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: "smpsThemeLight",
    themes: {
      smpsThemeLight,
    },
  },
});

export default vuetify;
