// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/smps-etusivu-1400x450.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-main {\n  background-color: #f0f0f0;\n}\n.app-main:before {\n  content: \"\";\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n  opacity: 0.05;\n}\n.app-bar-title {\n  font-weight: 500;\n  font-size: large;\n  color: rgba(var(--v-theme-primary));\n}\n.app-container {\n  max-width: 1400px;\n  padding-top: 40px;\n  padding-left: 40px;\n  padding-right: 40px;\n}\n.app-dialog-title {\n  color: rgba(var(--v-theme-primary));\n}\n.app-template-checklist-section-card {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.v-treeview-group .v-list-group .v-list-group__items .v-list-item:not(.v-list-group__header) .v-list-item__prepend {\n  margin-left: 30px;\n  margin-right: -18px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
