import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";
import { objectDiff, sureClone } from "../../lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:AnnouncementsStore`);

const DEFAULT_EDITOR = {
  id: null,
  title: null,
  collectionId: null,
  announcementCategoryId: null,
};

export default ModuleBase({
  state: {
    announcements: {},
    editor: sureClone(DEFAULT_EDITOR),
    announcementApiError: null,
  },

  getters: {
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.announcements, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
    announcementCategories({ announcements }) {
      let values = _.values(announcements);
      return values;
    },
    announcementsByCategoryId:
      ({ announcements }) =>
      (categoryId) => {
        var x = _.values(announcements).filter(
          (a) => a.announcementCategoryId === categoryId,
        );
        return x;
      },
    announcementApiError({ announcementApiError }) {
      return announcementApiError;
    },
  },
  mutations: {
    newAnnouncementToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addAnnouncement(state, announcement) {
      state.announcements[announcement.id] = announcement;
    },
    setAnnouncementApiError(state, error) {
      state.announcementApiError = error;
    },
    clearAnnouncements(state) {
      state.announcements = [];
    },
    deleteAnnouncement(state, id) {
      delete state.announcements[id];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      try {
        let items = await ApiClient.get(`/announcements`);
        debug("load", items);
        commit("set", ["announcements", _.keyBy(items, "id")]);
        commit("setAnnouncementApiError", null);
      } catch (error) {
        console.log("Error while fetching announcements", error);
        commit("setAnnouncementApiError", error);
      }
    },
    async loadAnnouncementToEditor({ state, commit }, id) {
      debug("loadAnnouncementToEditor", id);
      const item = _.get(state.announcements, id);
      if (!item) {
        debug("Announcement not found!");
        throw new Error("Announcement not found");
      }
      commit("set", ["editor", sureClone(item)]);
    },
    async createAnnouncement({ state, commit }) {
      let announcement = sureClone(state.editor);
      _.unset(announcement, "id");
      debug("createAnnouncement", announcement);
      let response = await ApiClient.post("/announcements", announcement);
      debug("createAnnouncement - response", response);
      commit("addAnnouncement", response);
      return response;
    },
    async saveAnnouncementInEditor({ state, getters, commit }) {
      let id = state.editor.id;
      if (!getters.editorHasChanges) {
        //throw new Error("Ei tallennettavia muutoksia");
        debug("saveAnnouncementInEditor", "Ei tallennettavia muutoksia.");
        return;
      }
      let modifiedItem = sureClone(state.editor);
      debug("saveAnnouncementInEditor", id, modifiedItem);
      let result = await ApiClient.put(`/announcements/${id}`, modifiedItem);
      debug("saveAnnouncementInEditor, result", result);

      if (result) {
        commit("addAnnouncement", result);
      }
    },
    async deleteAnnouncement({ commit }, announcementId) {
      debug("deleteAnnouncement", announcementId);
      let result = await ApiClient.delete(`/announcements/${announcementId}`);
      debug("deleteAnnouncement - result", result);
      commit("deleteAnnouncement", announcementId);
    },
  },
});
