import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:CollectionsStore`);

export default ModuleBase({
  state: {
    collections: {},
  },

  getters: {
    collections({ collections }) {
      let values = _.values(collections);
      return values;
    },
    collectionById:
      ({ collections }) =>
      (id) => {
        var x = _.values(collections).find((c) => c.id === id);
        return x;
      },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let collections = await ApiClient.get(`/collections`);
      debug("load", collections);
      commit("set", ["collections", _.keyBy(collections, "id")]);
    },
  },
});
