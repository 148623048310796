<template>
  <v-dialog :model-value="show" max-width="500" absolute>
    <v-card>
      <v-card-title v-if="title" class="app-dialog-title">{{
        title
      }}</v-card-title>
      <v-card-text v-if="message" class="pt-4 body-1">
        <span v-html="message"></span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="grey--text lighten-2"
          text
          @click="confirmCancel"
          :disabled="saving"
        >
          Peruuta
        </v-btn>
        <v-btn
          class="primary--text"
          outlined
          @click="confirmOk"
          :loading="saving"
        >
          Kyllä
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import emitter from "@/lib/eventBus";

export default {
  name: "ConfirmDialog",

  data() {
    return {
      show: null,
      title: null,
      message: null,
      async: false,
      saving: false,
    };
  },

  created() {
    let me = this;
    emitter.off("show-confirm");
    emitter.on("show-confirm", (payload) => {
      me.showConfirm(payload);
    });
    emitter.on("confirm.saved", me.reset);
  },

  methods: {
    confirmCancel() {
      let me = this;
      emitter.emit("confirm.cancel");
      me.reset();
    },

    confirmOk() {
      let me = this;
      emitter.emit("confirm.ok");
      if (!me.async) {
        me.reset();
      } else {
        me.saving = true;
      }
    },

    reset() {
      let me = this;
      me.show = false;
      me.title = null;
      me.message = null;
      me.saving = false;
      me.async = false;
    },

    showConfirm({ message, title = null, async = false }) {
      let me = this;
      me.$debug("showConfirm", message, title);
      me.title = title;
      me.message = message;
      me.show = true;
      me.async = async;
    },
  },
};
</script>
