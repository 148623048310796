import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { Auth0Plugin } from "./auth";
import GlobalMixin from "./mixins/GlobalMixin";

import "./mixins/GlobalMixin";

const app = createApp(App);

app.mixin(GlobalMixin);
app.use(router);
app.use(store);
app.use(vuetify);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: Sentry.browserTracingIntegration({ router }),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  advancedOptions: {
    // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
    defaultScope:
      "profile email read:checklists edit:checklists delete:checklists read:organisations edit:organisations delete:organisations read:templates edit:templates delete:templates read:announcementCategories read:announcements edit:announcements delete:announcements",
  },
  onRedirectCallback: (appState) => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      )
      .catch(() => {});
  },
});

app.mount("#app");
