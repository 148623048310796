import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import checklists from "./modules/checklists";
import checklistRows from "./modules/checklistRows";
import categories from "./modules/categories";
import collections from "./modules/collections";
import catalog from "./modules/catalog";
import announcementCategories from "./modules/announcementCategories";
import announcements from "./modules/announcements";
import templates from "./modules/admin/templates";
import templateCollections from "./modules/admin/templateCollections";
import organisations from "./modules/admin/organisations";
import vessels from "./modules/admin/vessels";
import vesselCatalogItem from "./modules/admin/vesselCatalogItem";
import users from "./modules/admin/users";
import features from "./modules/admin/features";
import roles from "./modules/admin/roles";
import account from "./modules/account";
import images from "./modules/images";
import appLocal from "./modules/appLocal";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["appLocal"],
});

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin],
  modules: {
    checklists,
    checklistRows,
    categories,
    catalog,
    announcementCategories,
    announcements,
    templates,
    templateCollections,
    organisations,
    vessels,
    vesselCatalogItem,
    users,
    roles,
    account,
    features,
    collections,
    images,
    appLocal,
  },
});

export default store;
