import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:VesselsStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  description: null,
  organisationId: null,
};

export default ModuleBase({
  state: {
    vessels: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    vessels({ vessels }) {
      let values = _.values(vessels);
      return values;
    },
    vesselsInOrganisation:
      ({ vessels }) =>
      (organisationId) => {
        let values = _.values(vessels);
        return values.filter((x) => x.organisationId === organisationId);
      },
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.vessels, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    newVesselToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addVessel(state, vessel) {
      state.vessels[vessel.id] = vessel;
    },
    deleteVessel(state, vesselId) {
      delete state.vessels[vesselId];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let vessels = await ApiClient.get(`/collections`);
      debug("load", vessels);
      commit("set", ["vessels", _.keyBy(vessels, "id")]);
    },
    async loadVesselToEditor({ state, commit }, vesselId) {
      debug("loadVesselToEditor", vesselId);
      const vessel = _.get(state.vessels, vesselId);
      debug("loadVesselToEditor", vesselId);
      if (!vessel) {
        throw new Error("Vessel not found");
      }
      commit("set", ["editor", sureClone(vessel)]);
    },
    async saveVesselInEditor({ state, getters, commit }) {
      let vesselId = state.editor.id;
      if (!getters.editorHasChanges) {
        throw new Error("Ei tallennettavia muutoksia");
      }
      let modifiedVessel = sureClone(state.editor);
      debug("saveVesselInEditor", vesselId, modifiedVessel);
      let result = await ApiClient.put(
        `/collections/${vesselId}`,
        modifiedVessel,
      );
      commit("addVessel", result);
      debug("saveVesselInEditor, result", result);
    },
  },
});
