import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import ModuleBase from "../../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:OrganisationsStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
};

export default ModuleBase({
  state: {
    organisations: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    organisations({ organisations }) {
      let values = _.values(organisations);
      return values;
    },
    organisationById:
      ({ organisations }) =>
      (id) => {
        var x = _.values(organisations).find((c) => c.id === id);
        return x;
      },
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.organisations, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    newOrganisationToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addOrganisation(state, organisation) {
      state.organisations[organisation.id] = organisation;
    },
    deleteOrganisation(state, organisationId) {
      delete state.organisations[organisationId];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let organisations = await ApiClient.get(`/organisations`);
      debug("load", organisations);
      commit("set", ["organisations", _.keyBy(organisations, "id")]);
    },
    async loadOrganisationToEditor({ state, commit }, organisationId) {
      debug("loadOrganisationToEditor", organisationId);
      const organisation = _.get(state.organisations, organisationId);
      debug("loadOrganisationToEditor", organisationId);
      if (!organisation) {
        throw new Error("Organisation not found");
      }
      commit("set", ["editor", sureClone(organisation)]);
    },
    async createOrganisation({ state, commit }) {
      let organisation = sureClone(state.editor);
      _.unset(organisation, "id");
      debug("createOrganisation", organisation);
      let response = await ApiClient.post("/organisations", organisation);
      debug("createOrganisation - response", response);
      commit("addOrganisation", response);
      return response;
    },
    async saveOrganisationInEditor({ state, getters, commit }) {
      let organisationId = state.editor.id;
      if (!getters.editorHasChanges) {
        throw new Error("Ei tallennettavia muutoksia");
      }
      let modifiedOrganisation = sureClone(state.editor);
      debug("saveOrganisationInEditor", organisationId, modifiedOrganisation);
      let result = await ApiClient.put(
        `/organisations/${organisationId}`,
        modifiedOrganisation,
      );
      commit("addOrganisation", result);
      debug("saveOrganisationInEditor, result", result);
    },
    async deleteOrganisation({ commit }, organisationId) {
      debug("deleteOrganisation", organisationId);
      let result = await ApiClient.delete(`/organisations/${organisationId}`);
      debug("deleteOrganisation - result", result);
      commit("deleteOrganisation", organisationId);
    },
    async enableFeature({ commit }, { organisationId, featureId }) {
      debug("enableFeature", organisationId);
      let result = await ApiClient.post(
        `/organisations/${organisationId}/enable-feature`,
        { featureId },
      );
      debug("enableFeature - result", result);
      commit("addOrganisation", result);
    },
    async disableFeature({ commit }, { organisationId, featureId }) {
      debug("disableFeature", organisationId);
      let result = await ApiClient.post(
        `/organisations/${organisationId}/disable-feature`,
        { featureId },
      );
      debug("disableFeature - result", result);
      commit("addOrganisation", result);
    },
  },
});
