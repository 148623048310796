import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ImagesStore`);

export default ModuleBase({
  state: {
    objectUrls: {},
  },
  mutations: {
    addBlob(state, blobInfo) {
      state.objectUrls[blobInfo.name] = blobInfo.blob;
    },
  },
  getters: {
    imageUrlByName:
      ({ objectUrls }) =>
      (name) => {
        var x = _.get(objectUrls, name);
        return x;
      },
  },
  actions: {
    async loadImage({ state, commit }, imageName) {
      debug("loadImage");
      var localObjectUrl = _.get(state.objectUrls, imageName);
      if (!localObjectUrl) {
        let objectUrl = await ApiClient.getFile(`/images/` + imageName);
        commit("addBlob", { name: imageName, blob: objectUrl });
      }
    },
    async sendImage(_, imageData) {
      let formData = new FormData();
      formData.append("file", imageData);

      let response = await ApiClient.postFile("/images", formData);
      debug("images - response", response);
      var uri = response.blob.name;
      return uri;
    },
  },
});
