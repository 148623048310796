import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";
import { objectDiff, sureClone } from "../../lib/DataUtils";

const debug = _debug(
  `${process.env.VUE_APP_PREFIX}:AnnouncementCategoriesStore`,
);

const DEFAULT_EDITOR = {
  id: null,
  title: null,
};

export default ModuleBase({
  state: {
    announcementCategories: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.announcementCategories, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
    announcementCategories({ announcementCategories }) {
      let values = _.values(announcementCategories);
      return values;
    },
    announcementCategoryById:
      ({ announcementCategories }) =>
      (id) => {
        var x = _.values(announcementCategories).find((c) => c.id === id);
        return x;
      },
  },

  mutations: {
    addAnnouncementCategory(state, announcementCategory) {
      state.announcementCategories[announcementCategory.id] =
        announcementCategory;
    },
    clearCategories(state) {
      state.announcementCategories = [];
    },
    deleteCategory(state, announcementCategoryId) {
      delete state.announcementCategories[announcementCategoryId];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let items = await ApiClient.get(`/announcementCategories`);
      debug("load", items);
      commit("set", ["announcementCategories", _.keyBy(items, "id")]);
    },
    async loadCategoryToEditor({ state, commit }, announcementCategoryId) {
      debug("loadAnnouncementCategoryToEditor", announcementCategoryId);
      const item = _.get(state.announcementCategories, announcementCategoryId);
      if (!item) {
        debug("Announcement category not found!");
        throw new Error("Announcement category not found");
      }
      commit("set", ["editor", sureClone(item)]);
    },
    async saveAnnouncementCategoryInEditor({ state, getters, commit }) {
      let id = state.editor.id;
      if (!getters.editorHasChanges) {
        //throw new Error("Ei tallennettavia muutoksia");
        debug(
          "saveAnnouncementCategoryInEditor",
          "Ei tallennettavia muutoksia.",
        );
        return;
      }
      let modifiedItem = sureClone(state.editor);
      debug("saveAnnouncementCategoryInEditor", id, modifiedItem);
      let result = await ApiClient.put(
        `/announcementCategories/${id}`,
        modifiedItem,
      );
      debug("saveAnnouncementCategoryInEditor, result", result);

      if (result) {
        commit("addAnnouncementCategory", result);
        //dispatch("templates/syncChangesInTarget", result, { root: true });
        //dispatch("catalog/syncChangesInTarget", result, { root: true });
      }
    },
  },
});
