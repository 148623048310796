<template>
  <v-container class="d-flex justify-center flex-column fill-height">
    <v-card
      v-if="!$auth.state.isAuthenticated && !$auth.state.loading"
      class="app-login-card"
    >
      <v-row justify="center">
        <v-col cols="8">
          <v-img
            class="app-login-card-image"
            :src="require('@/assets/smps-checklists-logo.png')"
            alt="SMPS Tarkistuslistat -logo"
            align-center
          />
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col class="text-center text-overline font-weight-black"
          >SMPS Tarkistuslistat</v-col
        >
      </v-row>
      <v-row style="margin-top: 40px">
        <v-col class="text-center">
          <v-btn color="primary" large @click="login"> Kirjaudu sisään </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<style lang="sass" scoped>
.app-login-card {
  justify: 'center';
  width: 360px;
  padding: 40px;
}
</style>
<script>
export default {
  name: "HomeView",
  components: {},
  methods: {
    // Log the user in
    login() {
      console.log("auth....");
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
