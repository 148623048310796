import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:AccountStore`);

export default ModuleBase({
  state: {
    info: {},
  },

  getters: {
    info({ info }) {
      return info;
    },
    checklistsFeatureEnabled({ info }) {
      return info.enabledFeatures?.some(
        (x) => x.technicalIdentifier === "checklists",
      );
    },
    announcementsFeatureEnabled({ info }) {
      return info.enabledFeatures?.some(
        (x) => x.technicalIdentifier === "announcements",
      );
    },
  },
  mutations: {},
  actions: {
    async load({ commit }) {
      debug("load");
      let accountInfo = await ApiClient.get(`/account`);
      debug("load", accountInfo);
      commit("set", ["info", accountInfo]);
    },
  },
});
