/* eslint-disable prettier/prettier */
import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import ModuleBase from "../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ChecklistsStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  isCrossCheckList: false,
  isInformationOnly: false,
  categoryId: null
};

export default ModuleBase({
  state: {
    checklists: {},
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    checklists({ checklists }) {
      let values = _.values(checklists);
      return values;
    },
    checklistById: ({ checklists }) => id => {
      var x = _.values(checklists).find(c => c.id === id);
      return x;
    },
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.checklists, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    }
  },

  mutations: {
    addChecklist(state, checklist) {
      state.checklists[checklist.id] = checklist;
    },
    deleteChecklist(state, checklistId) {
      delete state.checklists[checklistId];
    },
    clearChecklists(state) {
      state.checklists = [];
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let checklists = await ApiClient.get(`/checklists`);
      debug("load", checklists);
      commit("set", ["checklists", _.keyBy(checklists, "id")]);
    },
    async loadChecklistToEditor({ state, commit }, checklistId) {
      debug("loadChecklistToEditor", checklistId);
      const checklist = _.get(state.checklists, checklistId);
      debug("loadChecklistToEditor", checklist);
      if (!checklist) {
        throw new Error("Checklist not found");
      }
      commit("set", ["editor", sureClone(checklist)]);
    },
    async saveChecklistInEditor({ state, getters, commit, dispatch }) {
      let checklistId = state.editor.id;
      if (!getters.editorHasChanges) {
        debug("saveChecklistInEditor", "Ei tallennettavia muutoksia.")
        return;
      }
      let modifiedChecklist = sureClone(state.editor);
      debug("saveChecklistInEditor", checklistId, modifiedChecklist);
      let result = await ApiClient.put(
        `/checklists/${checklistId}`,
        modifiedChecklist
      );
      debug("saveChecklistInEditor, result", result);

      if(result)
      {
        commit("addChecklist", result);
        await dispatch("templates/syncChangesInTarget", result, { root: true })
        await dispatch("catalog/syncChangesInTarget", result, { root: true })
      }
    }
  }
});
