import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "../auth/authGuard";
import Home from "../components/Home.vue";
const Checklists = () => import("../components/Checklists.vue");
const PdfGeneration = () => import("../components/PdfGeneration.vue");
const PrivacyPolicy = () => import("../components/PrivacyPolicy.vue");
const AnnouncementList = () => import("../components/AnnouncementList.vue");
const Templates = () => import("../components/admin/Templates.vue");
const Organisations = () => import("../components/admin/Organisations.vue");
const Vessels = () => import("../components/admin/Vessels.vue");
const Profile = () => import("../components/Profile.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "" },
  },
  {
    path: "/checklists",
    name: "Checklists",
    beforeEnter: authGuard,
    meta: { title: "Tarkistuslistat" },
    component: Checklists,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    beforeEnter: authGuard,
    meta: { title: "Tarkistuslistapohjat" },
  },
  {
    path: "/pdf-generation",
    name: "PdfGeneration",
    component: PdfGeneration,
    beforeEnter: authGuard,
    meta: { title: "Paperilistojen luonti" },
  },
  {
    path: "/announcement-list",
    name: "AnnouncementList",
    component: AnnouncementList,
    beforeEnter: authGuard,
    meta: { title: "Tiedotuslista" },
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: Organisations,
    beforeEnter: authGuard,
    meta: { title: "Yhdistykset ja käyttäjät" },
  },
  {
    path: "/vessels",
    name: "Vessels",
    component: Vessels,
    beforeEnter: authGuard,
    meta: { title: "Alukset" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: authGuard,
    meta: { title: "Käyttäjäprofiili" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { title: "" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
