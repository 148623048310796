import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import { sureClone } from "../../../lib/DataUtils";
import ModuleBase from "../../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:VesselCatalogItemStore`);

const DEFAULT_EDITOR = {
  id: null,
  parentId: null,
  templateItemId: null,
};

const DEFAULT_COLLECTION_EDITOR = {
  name: null,
  description: null,
};

export default ModuleBase({
  state: {
    editor: sureClone(DEFAULT_EDITOR),
  },
  getters: {},
  mutations: {
    newCatalogItemToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    newCollectionToCatalogItemEditor(state) {
      state.editor.collection = sureClone(DEFAULT_COLLECTION_EDITOR);
    },
  },
  actions: {
    async createCatalogItem({ state, commit }) {
      let catalogItem = sureClone(state.editor);
      _.unset(catalogItem, "id");
      debug("createCatalogItem", catalogItem);
      let response = await ApiClient.post("/catalogItems", catalogItem);
      debug("createCatalogItem - response", response);

      if (response.collection != null) {
        commit("vessels/addVessel", response.collection, { root: true });
      }
      return response;
    },
    async deleteCatalogItemByCollectionId({ commit }, collectionId) {
      debug("deleteCatalogItemByCollectionId", collectionId);
      let result = await ApiClient.delete(
        `/catalogItems?collectionId=${collectionId}`,
      );
      debug("deleteCatalogItemByCollectionId - result", result);
      if (result?.collectionId != null) {
        commit("vessels/deleteVessel", result.collectionId, { root: true });
      }
    },
  },
});
