import { reactive, readonly } from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import * as _debug from "debug";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:Auth`);

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  const state = reactive({
    loading: true,
    isAuthenticated: false,
    isBasicUser: false,
    isSystemAdmin: false,
    isLocalStationPowerUser: false,
    isGlobalOrganisationPowerUser: false,
    user: {},
    auth0Client: null,
    popupOpen: false,
    error: null,
  });

  instance = {
    state: readonly(state),
    async handleRedirectCallback() {
      state.loading = true;
      try {
        await state.auth0Client.handleRedirectCallback();
        state.user = await state.auth0Client.getUser();
        state.isAuthenticated = true;
        state.isSystemAdmin = await instance.userHasRoleSystemAdmin();
        state.error = null;
      } catch (e) {
        state.error = e;
      } finally {
        state.loading = false;
      }
    },
    /** Authenticates the user using the redirect method */
    loginWithRedirect(o) {
      return state.auth0Client.loginWithRedirect(o);
    },
    /** Returns all the claims present in the ID token */
    getIdTokenClaims(o) {
      return state.auth0Client.getIdTokenClaims(o);
    },
    /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
    getTokenSilently(o) {
      return state.auth0Client?.getTokenSilently(o);
    },
    /** Gets the access token using a popup window */
    getTokenWithPopup(o) {
      return state.auth0Client.getTokenWithPopup(o);
    },
    userHasRolePowerUser: async () => {
      return await instance.userHasRole("local-station-power-user");
    },
    userHasRoleGlobalOrganisationPowerUser: async () => {
      return await instance.userHasRole("global-organisation-power-user");
    },
    userHasRoleSystemAdmin: async () => {
      return await instance.userHasRole("system-admin");
    },
    userHasRole: async (role) => {
      var audience = process.env.VUE_APP_AUTH0_AUDIENCE;
      var claims = await state.auth0Client.getIdTokenClaims();
      if (claims == null) {
        debug("User has no claims!");
        return false;
      }
      if (claims[audience + "/roles"] != null) {
        if (claims[audience + "/roles"].includes(role)) {
          debug("User has role '" + role + "'.");
          return true;
        }
        debug("User roles does not include '" + role + "'.");
        return false;
      }
      debug("User roles not defined!");
      return false;
    },
    /** Logs the user out and removes their session on the authorization server */
    logout(o) {
      return state.auth0Client.logout(o);
    },
  };

  /** Use this lifecycle method to instantiate the SDK client */
  (async () => {
    // Create a new instance of the SDK client using members of the given options object
    state.auth0Client = await createAuth0Client({
      ...options,
      client_id: options.clientId,
      redirect_uri: redirectUri,
      useRefreshTokens: true,
    });

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await state.auth0Client.handleRedirectCallback();

        state.error = null;

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      state.error = e;
    } finally {
      // Initialize our internal authentication state
      state.isAuthenticated = await state.auth0Client.isAuthenticated();
      state.isSystemAdmin = await instance.userHasRoleSystemAdmin();
      state.isLocalStationPowerUser = await instance.userHasRolePowerUser();
      state.isGlobalOrganisationPowerUser =
        await instance.userHasRoleGlobalOrganisationPowerUser();
      state.isBasicUser =
        state.isAuthenticated &&
        !state.isSystemAdmin &&
        !state.isLocalStationPowerUser &&
        !state.isGlobalOrganisationPowerUser;
      state.user = await state.auth0Client.getUser();
      state.loading = false;
    }
  })();
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(app, options) {
    app.config.globalProperties.$auth = useAuth0(options);
  },
};
