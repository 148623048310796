import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import ModuleBase from "../../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:UsersStore`);

const DEFAULT_EDITOR = {
  userId: null,
  email: null,
  password: null,
  organisationId: null,
  roles: null,
};

export default ModuleBase({
  state: {
    users: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    users({ users }) {
      let values = _.values(users);
      return values;
    },
    usersInOrganisation:
      ({ users }) =>
      (organisationId) => {
        let values = _.values(users);
        return values.filter((x) => x.organisationId === organisationId);
      },
    editorChanges(state) {
      const id = _.get(state, "editor.userId");
      if (!id) {
        return null;
      }
      const original = _.get(state.users, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    newUserToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addUser(state, user) {
      state.users[user.userId] = user;
    },
    deleteUser(state, userId) {
      delete state.users[userId];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let users = await ApiClient.get(`/users`);
      debug("load", users);
      commit("set", ["users", _.keyBy(users, "userId")]);
    },
    async loadUserToEditor({ state, commit }, userId) {
      debug("loadUserToEditor", userId);
      const user = _.get(state.users, userId);
      debug("loadUserToEditor", userId);
      if (!user) {
        throw new Error("User not found");
      }
      commit("set", ["editor", sureClone(user)]);
    },
    async createUser({ state, commit }) {
      let user = sureClone(state.editor);
      _.unset(user, "userId");
      debug("createUser", user);
      let response = await ApiClient.post("/users", user);
      debug("createUser - response", response);
      commit("addUser", response);
      return response;
    },
    async saveUserInEditor({ state, getters, commit }) {
      let userId = state.editor.userId;
      if (!getters.editorHasChanges) {
        throw new Error("Ei tallennettavia muutoksia");
      }
      let modifiedUser = sureClone(state.editor);
      debug("saveUserInEditor", userId, modifiedUser);
      let result = await ApiClient.put(`/users/${userId}`, modifiedUser);
      commit("addUser", result);
      debug("saveUserInEditor, result", result);
    },
    async deleteUser({ commit }, userId) {
      debug("deleteUser", userId);
      let result = await ApiClient.delete(`/users/${userId}`);
      debug("deleteUser - result", result);
      commit("deleteUser", userId);
    },
  },
});
