<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="$auth.state.isAuthenticated"
      v-model="drawer"
      color="primary"
      class="elevation-1"
      :expand-on-hover="
        this.isFullscreen ||
        this.$vuetify.display.md ||
        this.$vuetify.display.sm
      "
      :rail="
        this.isFullscreen ||
        this.$vuetify.display.md ||
        this.$vuetify.display.sm
      "
      mobile-breakpoint="sm"
    >
      <v-list class="mt-2 mb-2">
        <v-list-item
          :prepend-avatar="require('@/assets/smps-checklists-logo.png')"
          title="TARKISTUSLISTAT"
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item
          :title="userOrganisation"
          :subtitle="userType"
          prepend-icon="mdi-account"
          link
          to="/profile"
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.text"
          :title="item.text"
          :prepend-icon="item.icon"
          link
          :to="item.target"
        />
      </v-list>
      <template v-slot:append>
        <v-list nav>
          <v-list-item
            title="Kirjaudu ulos"
            prepend-icon="mdi-logout"
            @click="logout"
          />
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main class="app-main">
      <v-row>
        <v-col>
          <v-btn
            v-if="!drawer"
            icon="mdi-menu"
            variant="text"
            @click="
              () => {
                drawer = !drawer;
              }
            "
            position="absolute"
          ></v-btn>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-main>
    <AppToast></AppToast>
    <ConfirmDialog></ConfirmDialog>
    <InfoDialog></InfoDialog>
    <LoadingDialog :show="isLoading"></LoadingDialog>
  </v-app>
</template>
<style lang="sass">
.app-main {
  background-color: #f0f0f0;
  &:before {
    content: "";
      background-image: url('@/assets/smps-etusivu-1400x450.webp');
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.05;
  }
  }

.app-bar-title {
  font-weight: 500;
  font-size: large;
  color: rgba(var(--v-theme-primary));
}

.app-container {
  max-width: 1400px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.app-dialog-title {
  color: rgba(var(--v-theme-primary));
}

.app-template-checklist-section-card {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
<script>
import { watch } from "vue";
import AppToast from "./components/common/Toast";
import ConfirmDialog from "./components/common/Confirm";
import LoadingDialog from "./components/common/Loading";
import InfoDialog from "./components/common/Info";
import { fullscreenMixin } from "./mixins/FullscreenMixin";

export default {
  name: "App",
  components: {
    AppToast,
    ConfirmDialog,
    LoadingDialog,
    InfoDialog,
  },
  mixins: [fullscreenMixin],
  data: () => ({
    drawer: true,
    isLoading: false,
    isDataReady: false,
  }),
  computed: {
    items: {
      get() {
        let me = this;
        var basicMenuItems = [];
        if (me.checklistsFeatureEnabled) {
          basicMenuItems.push({
            icon: "mdi-format-list-checks",
            text: "Tarkistuslistat",
            target: "/checklists",
          });
        }

        if (me.announcementsFeatureEnabled) {
          basicMenuItems.push({
            icon: "mdi-bullhorn",
            text: "Tiedotuslista",
            target: "/announcement-list",
          });
        }

        var powerUserMenuItems = [];
        if (me.checklistsFeatureEnabled) {
          powerUserMenuItems.push({
            icon: "mdi-file-pdf-box",
            text: "Paperilistojen luonti",
            target: "/pdf-generation",
          });
        }

        var globalOrganisationPowerUserMenuItems = [
          {
            icon: "mdi-view-list-outline",
            text: "Tarkistuslistapohjat",
            target: "/templates",
          },
          {
            icon: "mdi-ferry",
            text: "Alukset",
            target: "/vessels",
          },
          {
            icon: "mdi-account-group-outline",
            text: "Yhdistykset ja käyttäjät",
            target: "/organisations",
          },
        ];

        var systemAdminMenuItems = [];

        var menuItems = [];

        if (this.$auth.state.isBasicUser) {
          menuItems = basicMenuItems;
        }

        if (this.$auth.state.isLocalStationPowerUser) {
          menuItems = basicMenuItems.concat(powerUserMenuItems);
        }

        if (this.$auth.state.isGlobalOrganisationPowerUser) {
          menuItems = globalOrganisationPowerUserMenuItems;
        }

        if (this.$auth.state.sSystemAdmin) {
          menuItems = basicMenuItems
            .concat(powerUserMenuItems)
            .concat(globalOrganisationPowerUserMenuItems)
            .concat(systemAdminMenuItems);
        }
        return menuItems;
      },
    },
    userName: {
      get() {
        return this.$auth.state.user.name;
      },
    },
    userPicture: {
      get() {
        return this.$auth.state.user.picture;
      },
    },
    userType: {
      get() {
        if (this.$auth.state.isBasicUser) {
          return "Aseman peruskäyttäjä";
        }

        if (this.$auth.state.isLocalStationPowerUser) {
          return "Aseman pääkäyttäjä";
        }

        if (this.$auth.state.isGlobalOrganisationPowerUser) {
          return "SMPS Pääkäyttäjä";
        }

        if (this.$auth.state.isSystemAdmin) {
          return "Järjestelmän pääkäyttäjä";
        }
        return "";
      },
    },
    account: {
      get() {
        return this.$store.getters["account/info"];
      },
    },
    checklistsFeatureEnabled: {
      get() {
        return this.$store.getters["account/checklistsFeatureEnabled"];
      },
    },
    announcementsFeatureEnabled: {
      get() {
        return this.$store.getters["account/announcementsFeatureEnabled"];
      },
    },
    userOrganisation: {
      get() {
        return this.$auth.state.isSystemAdmin
          ? "Järjestelmän ylläpitäjä"
          : this.account.organisationName;
      },
    },
  },
  methods: {
    // Log the user out
    async logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    resolveRedirectTarget() {
      let me = this;
      if (
        me.$auth.state.isSystemAdmin ||
        me.$auth.state.isGlobalOrganisationPowerUser
      ) {
        return "/templates";
      }
      if (me.checklistsFeatureEnabled) {
        return "/checklists";
      }
      if (me.announcementsFeatureEnabled) {
        return "/announcement-list";
      }
      return "/";
    },
  },
  async mounted() {
    let me = this;

    watch(
      () => this.$auth.state.loading,
      async (loading) => {
        if (loading === false && this.$auth.state.isAuthenticated === true) {
          try {
            me.isLoading = true;
            await me.$store.dispatch("account/load");

            if (
              me.$auth.state.isSystemAdmin ||
              me.$auth.state.isGlobalOrganisationPowerUser
            ) {
              await me.$store.dispatch("templates/loadRoot");
              await me.$store.dispatch("templateCollections/load");
              await me.$store.dispatch("organisations/load");
              await me.$store.dispatch("vessels/load");
              await me.$store.dispatch("users/load");
              await me.$store.dispatch("features/load");
              await me.$store.dispatch("roles/load");
            } else {
              await me.$store.dispatch("collections/load");

              if (me.checklistsFeatureEnabled) {
                await me.$store.dispatch("categories/load");
                await me.$store.dispatch("checklists/load");
                await me.$store.dispatch("checklistRows/load");
                await me.$store.dispatch("catalog/load");
              }
            }

            me.isDataReady = true;
            me.isLoading = false;

            if (this.$route.name == "Home") {
              var redirectTarget = me.resolveRedirectTarget();
              me.$redirect(redirectTarget);
            }
          } catch (err) {
            if (err.message !== "cancel") {
              me.isLoading = false;
              await me.$info(
                err.message,
                "Yhdistäminen palveluun epäonnistui!",
              );
              me.logout();
            }
          }
        }
      },
    );
  },
};
</script>
