import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:FeaturesStore`);

export default ModuleBase({
  state: {
    features: {},
  },

  getters: {
    features({ features }) {
      let values = _.values(features);
      return values;
    },
  },
  mutations: {},
  actions: {
    async load({ commit }) {
      debug("load");
      let features = await ApiClient.get(`/features`);
      debug("load", features);
      commit("set", ["features", _.keyBy(features, "technicalIdentifier")]);
    },
  },
});
