<template>
  <div class="app-confirm">
    <v-dialog :model-value="show" max-width="400" absolute>
      <v-card>
        <v-card-title
          v-if="title"
          class="primary--text subtitle-2 text-uppercase justify-center"
          >{{ title }}</v-card-title
        >
        <v-card-text v-if="message" class="pt-4 body-1 text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn left class="primary--text" text @click="confirmOk"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import emitter from "@/lib/eventBus";

export default {
  name: "InfoDialog",

  data() {
    return {
      show: null,
      title: null,
      message: null,
    };
  },

  created() {
    let me = this;
    emitter.off("show-info");
    emitter.on("show-info", (payload) => {
      me.showInfo(payload);
    });
  },

  methods: {
    confirmOk() {
      let me = this;
      emitter.emit("info.ok");
      me.reset();
    },

    reset() {
      let me = this;
      me.show = false;
      me.title = null;
      me.message = null;
    },

    showInfo({ message, title = null }) {
      let me = this;
      me.$debug("showInfo", message, title);
      me.title = title;
      me.message = message;
      me.show = true;
    },
  },
};
</script>
