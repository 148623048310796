import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";
import { objectDiff, sureClone } from "../../lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:CategoriesStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  parentId: null,
  information: null,
  printToPdfDefault: true,
};

export default ModuleBase({
  state: {
    categories: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    editorChanges(state) {
      const categoryId = _.get(state, "editor.id");
      if (!categoryId) {
        return null;
      }
      const original = _.get(state.categories, categoryId);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
    categories({ categories }) {
      let values = _.values(categories);
      return values;
    },
    categoryById:
      ({ categories }) =>
      (id) => {
        var x = _.values(categories).find((c) => c.id === id);
        return x;
      },
  },

  mutations: {
    addCategory(state, category) {
      state.categories[category.id] = category;
    },
    clearCategories(state) {
      state.categories = [];
    },
    deleteCategory(state, categoryId) {
      delete state.categories[categoryId];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let categories = await ApiClient.get(`/categories`);
      debug("load", categories);
      commit("set", ["categories", _.keyBy(categories, "id")]);
    },
    async loadCategoryToEditor({ state, commit }, categoryId) {
      debug("loadCategoryToEditor", categoryId);
      const category = _.get(state.categories, categoryId);
      if (!category) {
        debug("Category not found!");
        throw new Error("Category not found");
      }
      commit("set", ["editor", sureClone(category)]);
    },
    async saveCategoryInEditor({ state, getters, commit, dispatch }) {
      let categoryId = state.editor.id;
      if (!getters.editorHasChanges) {
        //throw new Error("Ei tallennettavia muutoksia");
        debug("saveCategoryInEditor", "Ei tallennettavia muutoksia.");
        return;
      }
      let modifiedCategory = sureClone(state.editor);
      debug("saveCategoryInEditor", categoryId, modifiedCategory);
      let result = await ApiClient.put(
        `/categories/${categoryId}`,
        modifiedCategory,
      );
      debug("saveCategoryInEditor, result", result);

      if (result) {
        commit("addCategory", result);
        dispatch("templates/syncChangesInTarget", result, { root: true });
        dispatch("catalog/syncChangesInTarget", result, { root: true });
      }
    },
  },
});
