import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../../common/ModuleBase";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:RolesStore`);

export default ModuleBase({
  state: {
    roles: {},
  },

  getters: {
    roles({ roles }) {
      let values = _.values(roles);
      return values;
    },
  },

  mutations: {},
  actions: {
    async load({ commit }) {
      debug("load");
      let roles = await ApiClient.get(`/roles`);
      debug("load", roles);
      commit("set", ["roles", _.keyBy(roles, "roleId")]);
    },
  },
});
